<template>
  <v-dialog v-model="visible" max-width="600" max-height="800">
    <v-card>
      <v-toolbar>
        <v-toolbar-title class="title" justify="center"
          >Gesundheit Löschen</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-icon class="icon-style-delete"> mdi mdi-trash-can-outline</v-icon>
        <div class="delete-text">
          <p class="text-row">Wollen Sie diesen Baumart wirklich</p>
          <p class="text-row">löschen? Dieser Vorgang kann nicht</p>
          <p class="text-row">rückgängig gemacht werden.</p>
        </div>

        <v-row>
          <v-btn
            type="button"
            class="emptyForm"
            color="gray"
            :disabled="formDeleted"
            @click="closeModal"
            >Abbrechen</v-btn
          >
          <v-btn
            type="submit"
            class="confirmForm"
            color="error"
            :loading="isLoading"
            :disabled="formDeleted"
            @click="deleteHealth()"
            >Löschen</v-btn
          >
        </v-row>
        <v-alert
          type="success"
          elevation="20"
          v-show="showAlert"
          :color="alertColor"
          class="alert"
        >
          {{ alertText }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  formDeleted: false,
  props: {
    health: {
      type: Object
    },
    visible: Boolean,
    itemList: Array
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      alertText: "",
      alertColor: ""
    };
  },
  methods: {
    closeModal() {
      this.formDeleted = false;
      this.$emit("close");
    },
    deleteHealth() {
      this.isLoading = true;
      axios
        .delete(
          `https://tilia-ch.rrota.org/api/Health/Delete/${this.health.id}`
        )
        .then(response => {
          this.isLoading = false;
          this.formDeleted = true;
          const index = this.itemList
            ? this.itemList.findIndex(item => item.id === this.health.id)
            : -1;
          if (index >= 0) {
            this.itemList.splice(index, 1);
          }
          if (response.status === 200) {
            this.showAlert = true;
            this.alertColor = "success";
            this.alertText = "Dieser Gesundheit wurde erfolgreich gelöscht";
            this.$emit("updated-table", true);
            setTimeout(() => {
              this.closeModal();
              this.showAlert = false;
            }, 2000);
          }
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.showAlert = true;
          this.alertColor = "error";
          this.alertText = "Fehler beim Löschen der Gesundheit";
          setTimeout(() => {
            this.showAlert = false;
          }, 2000);
        });
    }
  }
};
</script>
<style>
.delete-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon-style-delete {
  color: #c82210 !important;
  font-size: 220px;
  margin-top: 36px; /* Adjust as needed */
  margin-left: 260px;
  margin-bottom: 30px;
}

.title {
  text-align: center;
  width: 100%;
}

.confirmForm {
  color: white !important;
  display: flex;
  margin-top: 20px; /* Adjust as needed */
  margin-left: auto;
  margin-right: auto;
  width: 203px;
  margin-top: 38px; /* Adjust as needed */
}

.emptyForm {
  display: flex;
  width: 203px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 38px; /* Adjust as needed */
}

/* Additional styling for the three-row text */
.text-row {
  margin-top: 26px; /* Adjust as needed */
  margin: -12px;
  color: white; /* Set text color to white */
}
</style>
